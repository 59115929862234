.c-renblad-part-menu {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  margin: 0;
  margin-top: $spacing;
  margin-bottom: -$spacingLarge;
  padding-bottom: $spacingLarge;
  &__divider {
    flex-grow: 1;
    align-self: center;
    margin-top: $spacingSmall;
    padding-bottom: $spacingSmall;
    border-top: 1px solid $color3000Secondary;
  }
  &__actions {
    display: flex;
    gap: $spacingSmall;
    flex-flow: row nowrap;
    overflow: hidden;
    margin-right: $spacingSmall;
    border: 1px solid $color3000Secondary;
    box-sizing: border-box;
    background-color: white;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 5px;
    &__divider {
      display: inline;
      border-left: 1px solid $color3000TertiaryShade;
      margin: $spacingSmall (-$spacingTiny);
    }
    button {
      padding: $spacingSmall;
    }
    button:hover {
      background-color: $colorFog;
    }
  }
}

.c-renblad-part-menu:hover + * {
  background-color: $colorFog;
}

.c-renblad-part-menu:hover {
  background-color: $colorFog;
}

.renbladpart-metadata {
  display: flex;
}
