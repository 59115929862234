@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src:
    url("/fonts/roboto-v20-latin/roboto-v20-latin-300.eot") format("eot"),
    url("/fonts/roboto-v20-latin/roboto-v20-latin-300.woff") format("woff"),
    url("/fonts/roboto-v20-latin/roboto-v20-latin-300.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    url("/fonts/roboto-v20-latin/roboto-v20-latin-regular.eot") format("eot"),
    url("/fonts/roboto-v20-latin/roboto-v20-latin-regular.woff") format("woff"),
    url("/fonts/roboto-v20-latin/roboto-v20-latin-regular.woff2")
      format("woff2");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src:
    url("/fonts/roboto-v20-latin/roboto-v20-latin-500.eot") format("eot"),
    url("/fonts/roboto-v20-latin/roboto-v20-latin-500.woff") format("woff"),
    url("/fonts/roboto-v20-latin/roboto-v20-latin-500.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/fonts/roboto-v20-latin/Roboto-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("/fonts/roboto-v20-latin/Roboto-Black.ttf") format("truetype");
}
