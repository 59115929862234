@import "tocbot/tocbot";

.c-toc-mobile-content {
  font-size: 14px;
  font-weight: 400;
  ol {
    list-style: none;
  }
}

.c-toc {
  padding: $spacing 0;

  &__title {
    margin-bottom: $spacingSmall;
    font-weight: 500;
    font-size: 14px;
  }
  &__content {
    @extend .c-toc-mobile-content;

    overflow-y: auto;
    max-height: 85vh;
    position: relative;

    .toc-list-item {
      .is-active-link:before {
        margin-top: 3px;
      }
    }
  }

  @media print {
    // show every item in the ToC list
    .isCollapsible {
      max-height: 100%;
    }

    .is-collapsed {
      max-height: 100%;
    }
  }
}
