.c-accordion__label {
  button {
    text-align: left;
    font-size: 1em;
  }
}

.c-accordion__arrow {
  display: inline-block;
  text-align: right;
  margin-left: 0.25em;

  button {
    color: $colorBrightGreen;
  }
}
