.c-renblad-figure-link {
  button {
    &:focus {
      outline: none;
    }
  }
  &__content {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    visibility: hidden;
    z-index: 600;
    padding: $spacingSmall;
  }
}

.c-renblad-image:hover .c-renblad-figure-link__content {
  visibility: visible;
}
