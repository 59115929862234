.c-card-employee {
  background-color: $colorPineGreen;
  margin-bottom: $spacingTiny;
}

.c-card-employee--padding-smallVersion {
  .c-card-employee__credentials {
    padding: $spacingSmall $spacing;
  }
}

.c-card-employee--light {
  background-color: $colorGreySky;
}

.c-card-employee__head {
  display: flex;
}
.c-card-employee__image {
  object-fit: cover;
}

.c-card-employee__credentials {
  padding-left: $spacing;
  h3 {
    line-height: 1.7;
    color: $colorWhite;
  }
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.c-card-employee__role {
  color: $colorWhite;
  font-weight: normal;
}

.c-card-employee--light .c-card-employee__credentials {
  h3 {
    color: $colorPineGreen;
  }
}

.c-card-employee--light .c-card-employee__role {
  color: $colorPineGreen;
}

.c-card-employee__email {
  width: 100%;
  color: $colorWhite;
  text-decoration: underline;
  line-height: 1.7;
}

.c-card-employee__email:hover {
  font-weight: 500;
  text-decoration: underline;
}

.c-card-employee--light .c-card-employee__email {
  color: $colorBlack;
}

.c-card-employee__phone {
  width: 100%;
  color: $colorWhite;
  text-decoration: underline;
  line-height: 1.7;
}

.c-card-employee__phone:hover {
  font-weight: 500;
  text-decoration: underline;
}
.c-card-employee--light .c-card-employee__phone {
  color: $colorBlack;
}

.c-card-employee__body {
  margin-top: $spacing;
  margin-bottom: 0;
  line-height: 1.2;
}

@media (max-width: 59.99em) {
  .c-card-employee__credentials {
    h3 {
      font-size: $fontLarge;
    }
  }

  .c-card-employee__body {
    display: none;
  }
  .c-card-employee__credentials {
    padding: $spacing;
  }
}

@media (min-width: 60em) {
  .c-card-employee--padding {
    padding: $spacingLarge $spacingLarge $spacing $spacingLarge;
  }
}
