.c-renblad-filter {
  font-size: $fontTiny;
  display: none; //hide filter
}

//add class to show filter
.c-renblad-filter--show {
  display: flex;
  flex-wrap: wrap;
}
.c-renblad-filter p {
  margin-bottom: 0;
}

.c-renblad-filter__block {
  min-width: 50%;
}

.c-renblad-filter-open {
  display: block;
}

@media (min-width: 900px) {
  .c-renblad-filter {
    display: flex; //show filter on desktop
    flex-direction: row;
    justify-content: space-between;
  }
  .c-renblad-filter__toggle-button {
    display: none; //Hide button on dektop
  }
  .c-renblad-filter__block {
    min-width: auto;
  }
}

.c-renblad-filter--single {
  display: block;
  margin: $spacingTiny 0;
  padding-right: $spacing;
  font-size: $fontTiny;

  //.checkbox {
  //  height: 16px;
  //  padding-left: 25px;
  //  margin-bottom: 0px;
  //
  //  .checkmark {
  //    width: 16px;
  //    height: 16px;
  //
  //    &:after {
  //      left: 4px;
  //      top: 0px;
  //    }
  //  }
  //}
}
