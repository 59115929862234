.c-selector {
  margin-top: $spacing;
  width: 100%;
}

.c-selector__flex {
  display: flex;
}

.c-selector__item {
  border: none;
  background-color: $colorFog;
  width: 15%;
  padding: $spacingSmall $spacing;
  font-size: $fontSmall;
  margin-right: $spacing;
  cursor: pointer;

  &:hover {
    background: $colorVanilla;
  }
}
@media (max-width: 59.99em) {
  .c-selector__item {
    display: none;
  }
}

@media (min-width: 60em) {
  .c-selector--hide-component {
    display: none;
  }
}
