.c-richText-formula {
  padding: 5px;
  margin: 5px 0px;

  .katex-error {
    padding: 1.1rem;
    border-left: 5px solid red;
    background-color: pink;
    svg {
      max-height: 26px;
    }
  }
  .katex-editor {
    background: #000;
    color: #fff;
    padding: 10px 5px;
    margin: 10px 0px;
  }
  .formula-preview {
    font-size: 1rem;
    padding: 1.1rem;
  }
  .katex-wrapper {
    .katex {
      font:
        normal 1.5em KaTeX_Main,
        Times New Roman,
        serif;
      line-height: 1.2;
      white-space: normal;
      text-indent: 0;
    }
  }
}

.c-richText-editor__content {
  .c-richText-formula {
    border: 1px solid;
    border-radius: 5px;
    padding: 5px;
  }
}
