.c-link-arrow {
  font-size: var(--body-link-font-size);
}
.c-link {
  color: $colorPineGreen;
  text-decoration: none;
}
.c-link:hover {
  font-weight: 500;
  text-decoration: underline;
}
