.c-tool-list {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  text-align: center;
}

.c-tool-list__item {
  background-color: $colorGreySky;
  width: calc(50% - 10px);
  margin: 5px;
  box-sizing: border-box;
  transition: transform 0.15s ease-in-out;
}
.c-tool-list__item:first-child {
  background-color: $colorPineGreen;
}
.c-tool-list__item:first-child .c-tool-list__text {
  color: $colorWhite;
}
.c-tool-list__item:hover {
  transform: translate($spacing/5, $spacingNegative/5);
}

.c-tool-list__image {
  position: relative;
}
.c-tool-list__image--sticker {
  position: absolute;
  top: var(--space-xxx-small);
  right: var(--space-xxx-small);
  padding: var(--space-xxx-small);
  text-transform: uppercase;
  border-radius: var(--space-xx-small);
  background-color: var(--plum-color);
  color: var(--white-color);
  font-weight: var(--font-weight);
  font-size: var(--label-font-size);
}

.c-tool-list__image svg,
.c-tool-list__image img {
  height: 50px;
  width: auto;
  padding-top: $spacing;
}

.c-tool-list__text {
  color: $colorBlack;
  margin: 0;
  padding-top: 10px;
  font-weight: 400;
  padding-bottom: $spacing;
}

@media (min-width: 516px) {
  .c-tool-list__item {
    width: 154px;
  }
}
