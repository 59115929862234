.suspense-loading-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-block: var(--space-xxx-large);

  .loading-spinner__icon {
    --speed: 600ms;
    --size: var(--space-large);
    --width: var(--space-xx-small);
    --color: var(--pine-green-color);
  }
}
