.c-rich-navbar {
  display: inline-flex;
  flex-wrap: wrap;
  gap: var(--space-xxx-small);
  padding: var(--space-xxx-small);
  background-color: $color3000Primary;
  border-radius: var(--space-xxx-small);
  svg {
    width: var(--heading-medium-font-size);
    height: var(--heading-medium-font-size);
  }
  @media screen and (min-width: $renblad-desktop) {
    svg {
      width: var(--space-small);
      height: var(--space-small);
    }
  }

  &__button {
    min-width: 1rem;
  }
}

.c-simple-rich-navbar {
  width: 100%;
}

.c-rich-navbar > *,
.c-rich-navbar > * label {
  padding: $spacingTiny;
}

.c-rich-navbar__item-divider {
  width: 1px;
  padding: 0;
  margin: 12px 0;
  align-self: stretch;
  background: #f0f0f0;
}

.c-rich-navbar__bonus_buttons {
  background-color: $color3000Primary;
  padding: $spacingSmall;
  height: unset;
  z-index: 51;
}

.c-rich-navbar__bonus_icon {
  svg {
    stroke: $colorAir;
    fill: $colorAir;
  }
}

.c-rich-navbar .c-rich-navbar__upload-btn {
  padding: 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  & input:focus + label {
    outline: 2px solid $colorSky;
  }
}
