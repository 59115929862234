.c-image-gallery {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.c-image-gallery__image-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: $spacingSmall;
}

.c-image-gallery__image {
  width: 100%;
  height: auto;
}

.c-image-gallery__caption {
  padding: 0 $spacingSmall;
  font-size: $fontSmall;
  margin-top: 0;
}

@media (min-width: 60em) {
  .c-image-gallery {
    flex-direction: row;
    justify-content: space-between;
  }

  .c-image-gallery__image {
    //height: 100%;
  }

  .c-image-gallery__image-wrapper--two-wide {
    flex-grow: 1;
    flex-basis: 50%;
    padding: $spacingSmall;
  }

  .c-image-gallery__image-wrapper--two-tall {
    flex-grow: 1;
    flex-basis: 50%;
    padding: $spacingSmall;
  }

  .c-image-gallery__image-wrapper--one-tall-two-wide {
    padding: $spacingSmall;
    flex-grow: 1;
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    img {
      max-height: 48%;
    }
  }

}