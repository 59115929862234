.c-article-mosaic-item__arrow {
  height: var(--space-small);
  width: var(--space-small);
  margin-top: var(--space-small);
}

.c-article-mosaic {
  display: flex;
  flex-direction: column;
  gap: $spacing;
}

.c-article-mosaic-item {
  height: 100%;
  color: $colorBlack;
  background-color: var(--white-color);
  display: flex;
  flex-direction: column;
  transition: transform 0.15s ease-in-out;
  border: 1px solid var(--black-color);
}

.c-article-mosaic-item__image {
  object-fit: cover;
  object-position: 50% 50%;
  max-width: 100%;
  max-height: 100%;
}

.c-article-mosaic-item__content {
  padding: $spacing;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  .c-article-mosaic-item__lede,
  .c-article-mosaic-item__datetag {
    font-size: $fontSmall;
    margin: 0 0 $spacing 0;
  }
}

.c-article-mosaic-item__title {
  margin: $spacingSmall 0 $spacing;
}

.c-article-mosaic-item__lede {
  display: none;
}

@media screen and (max-width: 59.99em) {
  .c-article-mosaic {
    grid-auto-rows: auto;
  }

  .c-article-mosaic-item__content {
    height: 100%;
    .c-article-mosaic-item__datetag {
      margin: 0;
    }
  }
  .c-article-mosaic-item__image {
    width: 100%;
  }
  .c-article-mosaic-item__title {
    font-size: $fontLarge;
  }
}

@media screen and (min-width: 40em) {
  .c-article-mosaic {
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 60em) {
  .c-article-mosaic {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media all and (-ms-high-contrast: none) {
  .c-article-mosaic {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .c-article-mosaic-item {
    flex: 0 1 32%;
    flex-flow: row wrap;
    margin-bottom: 1em;
    height: 100%;
    p {
      max-width: 100%;
    }

    &:nth-child(4),
    &:nth-child(6) {
      flex-flow: row wrap;
      .c-article-mosaic-item__image {
        max-width: 100%;
      }
    }

    .c-article-mosaic-item__content {
      width: 100%;
      p:last-child {
        display: inline;
      }
      .c-article-mosaic-item--ie11 {
        width: 90%;
      }
    }

    .c-article-mosaic-item__image {
      max-height: 16em;
      width: 100%;
    }
  }
}
