.c-portableText {
  font-size: var(--body-font-size);
  .c-description-list__title {
    background-color: #cce9f2;
    font-size: var(--font-size);
    padding: var(--space-xx-small);
    margin-bottom: 0;
  }
  li {
    line-height: 1.5;
  }
  dd > ul,
  dd > .c-portableText > ul {
    margin-top: 0;
  }
  dd {
    .c-renblad-content {
      ul {
        margin-top: 0;
      }
    }
  }

  dl {
    display: grid;
    grid-template: auto / 15em 1fr;
    padding: var(--space-xx-small);
    margin-top: 0;
  }

  .c-description-list-compact-container {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
    height: 100%;

    dl.c-description-list-compact-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      font-size: var(--body-font-size);
      padding: 0;
      line-height: 1;

      dt {
        padding: 0;
        font-weight: normal;
      }
      dt.warning {
        color: var(--warning-red-color);
      }

      dd {
        padding: 0;
      }
    }
  }

  dt,
  dd {
    margin: 0;
    border-bottom: 1px solid #cce9f2;
    padding: var(--space-x-small) 0;
    line-height: 1.7;
  }
  dt,
  dt p {
    font-weight: 500;
  }
  dd {
    p:first-of-type {
      margin-top: 0;
    }
    p:last-of-type {
      margin-bottom: var(--space-x-small);
    }
    .c-renblad-content {
      max-width: 100%;
    }
  }

  @media (max-width: 40em) {
    dl {
      grid-template: auto / 1fr;
    }
    dt {
      border-bottom: unset;
    }
  }

  .c-description-list {
    display: grid;
    grid-template-columns: 1fr;

    .c-description-pair {
      display: grid;
      gap: var(--space-x-small);
      grid-template: auto / 14em 1fr;

      dt {
        white-space: pre-line;
      }

      @media (max-width: 40em) {
        dt {
          white-space: normal;
        }
        grid-template: auto / 1fr;
      }

      @media print {
        display: flex;
        flex-direction: row;
        dt {
          width: 24%;
          margin-right: 4%;
        }
        dd {
          width: 72%;
        }
      }
    }
  }

  @media (max-width: 40em) {
    .c-description-list {
      grid-template: auto / 1fr;
    }
  }
}

.c-richText-editor__content {
  dt p {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.c-portableText--renblad {
  .c-description-list__title {
    background-color: var(--tertiary-color);
  }
  dt,
  dd {
    border-bottom: unset;
  }
  .c-description-list .c-description-pair {
    border-bottom: 1px solid var(--tertiary-color);
  }
}
