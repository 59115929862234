button[disabled],
button.disabled,
a[disabled],
a.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.c-button,
c-button-primary-louminous {
  position: relative;
  background-color: $colorLuminousGreen;
  color: $colorBlack;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: var(--button-font-size);
  margin-top: $spacing;
  padding: $spacingSmall $spacing;
  font: inherit;
  border-radius: 5px;
  border: 3px solid $colorLuminousGreen;
  transition:
    background-color 0.3s ease,
    border-color 0.3s ease;
}

.c-button svg {
  vertical-align: text-top;
}

.c-button:hover {
  color: $colorBlack;
  background-color: $colorBrightGreen;
  border-color: $colorBrightGreen;
}

a.c-button-htmlrenblad-beta:after {
  content: "Prøv";
  font-size: 0.8em;
  background-color: $colorMagenta;
  border: 1px solid $colorMagenta;
  border-radius: 3px;
  padding: 3px;
  color: $colorWhite;
  position: absolute;
  top: -15px;
  right: $spacingTiny;
}

.c-button-secondary-green {
  background-color: $colorWhite;
  color: $colorPineGreen;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: $font;
  font: inherit;
  margin-top: $spacing;
  padding: $spacingSmall $spacing;
  border: 3px solid $colorPineGreen;
  border-radius: 5px;
}

.c-button-primary-magenta {
  display: flex;
  align-items: center;
  background-color: var(--plum-color);
  color: var(--white-color) !important;
  text-align: center;
  text-decoration: none;
  font-size: var(--button-font-size);
  margin-top: var(--space-small);
  padding: var(--space-x-small) var(--space-medium);
  border-radius: 5px;
  transition:
    background-color 0.3s ease,
    border-color 0.3s ease;

  &:hover {
    background-color: var(--magenta-color);
  }
}

.c-button-secondary-magenta {
  background-color: var(--white-color);
  color: var(--plum-color);
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  font: inherit;
  font-size: var(--font-size);
  margin-top: var(--space-small);
  padding: var(--space-x-small) var(--space-medium);
  border-radius: 5px;
  border: 3px solid var(--plum-color);
}

.c-button-secondary-magenta:hover {
  color: var(--magenta-color);
  border: 3px solid var(--magenta-color);
}

.c-button-secondary-magenta:focus {
  background-color: #eee;
}

.c-button-secondary-louminous {
  background-color: $colorWhite;
  color: $colorDarkPine;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: $font;
  font: inherit;
  margin-top: $spacing;
  padding: $spacingSmall $spacing;
  border-radius: 5px;
  border: 3px solid $colorPineGreen;
  transition: border-color 0.3s ease;
}

.c-button-secondary-louminous:hover {
  border-color: $colorBrightGreen;
}

.c-button-secondary-green svg {
  vertical-align: middle;
}

.c-button-secondary-green:hover {
  color: $colorDarkPine;
  border: 3px solid $colorPineGreen;

  background-color: $colorWhite;
}

.c-button-secondary-green:focus {
  background-color: #eee;
}

.c-button-themed {
  background-color: var(--primary-color);
  color: var(--tertiary-color);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: $font;
  font: inherit;
  margin-top: $spacing;
  padding: $spacingSmall $spacing;
  border: 2px solid var(--tertiary-color);
}

.c-button-themed:hover {
  color: var(--tertiary-shade);
  background-color: var(--secondary-color);
}

.c-button-themed svg {
  fill: var(--tertiary-color);

  &:hover {
    fill: var(--tertiary-shade);
  }
}

.c-button-simple {
  text-decoration: underline;
  margin-top: $spacing;
  padding: $spacingSmall;
  color: $colorPineGreen;
  font-size: $font;
}

.c-button-primary-green {
  background-color: $colorPineGreen;
  color: $colorWhite;
  text-align: center;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  display: inline-block;
  font-size: $font;
  font: inherit;
  margin-top: $spacing;
  padding: $spacingSmall $spacing;
  border-bottom: 0px;
  transition: background-color 0.3s ease;
  border-radius: 5px;
}

.c-button-primary-green:hover,
.c-button-primary-green:focus {
  background-color: $colorBrightGreen;
  color: $colorPineGreen;
}

.c-button-warning-red-danger {
  background-color: var(--warning-red-color);
  color: var(--white-color);
  text-align: center;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  display: inline-block;
  font-size: $font;
  font: inherit;
  margin-top: $spacing;
  padding: $spacingSmall $spacing;
  border-bottom: 0px;
  transition: background-color 0.3s ease;
  border-radius: 5px;
}
.c-button-warning-red-danger:hover,
.c-button-warning-red-danger:focus {
  text-decoration: underline;
}

.c-button__icon {
  margin-left: $spacingSmall;
}

.c-button--underline {
  text-decoration: underline;
}

.c-rich-navbar-button {
  color: $colorWhite;
  font-size: $fontTiny;
  display: flex;
  gap: var(--space-xxx-small);
  align-items: center;
  border-radius: 5px;
  height: 100%;
  &__icon {
    vertical-align: text-bottom;
    width: 20px;
    height: 20px;
  }
  &__text {
    text-decoration: underline;
    font-size: $fontTiny;
    font-weight: 400;
  }
  &--active {
    background-color: $colorGreySky;
    color: $colorBlack;
  }
  &:focus {
    outline: 2px solid $colorSky;
  }
}
