.c-richText-editor {
  padding-right: var(--space-small);
  .c-richText-image-box {
    border: 2px solid var(--primary-color);
    padding: var(--space-x-small);
  }
}

.c-richText-editor__bar {
  position: sticky;
  top: 5rem;
  z-index: z-index(content-top);
}

.c-richText-editor__content {
  padding: 0 var(--space-x-small);
  border: 2px solid $colorLuminousGreen;
  border-radius: 8px;

  .slate-placeholder {
    margin-top: 0;
  }

  .with-highlight {
    display: flex;
    flex-direction: column;

    .c-richText-image-box,
    .c-richText-formula {
      flex: 1;
    }

    &.selected {
      .c-richText-image-box,
      .c-richText-formula,
      .c-richText-nb-textblock,
      .c-renblad-table,
      .c-todo__arbeidsversjon,
      .c-description-list-container,
      .c-info-yellow {
        outline: 2px solid var(--bright-green-color);
      }
    }
  }
}

.c-richText-editor__content-controls {
  display: flex;
  justify-content: flex-end;
  gap: $spacingSmall;
  margin-top: -$spacing;
  margin-bottom: $spacing;
  position: sticky;
  bottom: $spacingSmall;
}

.c-richText-image-warning {
  border: 5px solid var(--warning-red-color);
  border-color: var(--warning-red-color);
}

.c-richText-image-warning::after {
  content: "warning";
  border: 5px;
  width: 50px;
  background-color: var(--warning-red-color);
}

.c-richText-image {
  display: block;
  //box-shadow: 0 0 0 3px #b4d5ff;
  button {
    width: 100%;
    text-align: left;
    font-weight: normal;
  }

  div {
    display: flex;
    gap: 1em;
  }

  dl {
    dt {
      font-weight: bold;
    }
    dd {
      margin-left: 0;
    }
  }

  img {
    width: 150px;
    object-fit: contain;
  }
  .remove-image-button {
    margin-top: 0;
    width: auto;
  }
  .replace-image-button {
    font-size: var(--label-font-size);
    color: var(--dark-pine-color);

    button {
      margin-top: 0;
    }

    .replace-image-form {
      width: 100%;
      border: 1px solid var(--dark-pine-color);
      padding-inline: var(--space-x-small);
      display: flex;
      align-items: center;
      input {
        display: block;
        flex: auto;
      }
      button {
        flex: 0;
        margin-top: 0;
      }
    }
  }
}

.c-richText-hovering-menu {
  padding: 8px 7px 6px;
  position: absolute;
  z-index: z-index(content);
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  opacity: 0;
  background-color: $colorWhite;
  filter: drop-shadow(0.4rem 0.4rem 0.45rem rgba(0, 0, 30, 0.5));
  border-radius: 4px;
  transition: opacity 0.75s;
  & > * {
    display: inline-block;
  }
  & > * + * {
    margin-left: 15px;
  }
}
.c-richText-todo {
  background-color: $color6000TertiaryShade;
  &--focus {
    background-color: $color6000Tertiary;
    hr {
      border-top: 1px solid $color6000Secondary;
    }
  }
}

.c-richText-nb-textblock {
  box-shadow:
    inset 0 0 0 5px transparent, /* Left and right "borders" */
    inset 0 5px 0 0 var(--primary-color), /* Top border */
    inset 0 -5px 0 0 var(--primary-color);

  border-radius: 1px;
  background-color: var(--tertiary-color);
  padding: $spacing;
  margin-bottom: $spacing;

  &__focus {
    box-shadow:
      inset 0 0 0 5px var(--primary-color); /* Left and right "borders" on focus */
    border-radius: 3px;
  }

  &__label {
    font-weight: 300;
    padding-bottom: $spacingSmall;
    border-bottom: 1px solid var(--primary-color);
  }
  .c-renblad-content {
    max-width: 100%;
  }
}

.c-richText-split-document {
  &--focus {
    hr {
      border-top: 1px solid $colorLuminousGreen;
    }
  }
}
