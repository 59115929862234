.c-grid-select {
  &_cell {
    padding: 0.75rem;
    margin: 0.1rem;
    background-color: $colorGreySky;
    cursor: pointer;

    &_hover {
      background-color: $colorFog;
    }
  }

  &_cell-display {
    text-align: center;
    margin: $spacingTiny;
  }
}
