.c-speaker-list {
  background-color: $colorVanilla;
  padding: $spacing 0;
  margin-bottom: $spacing;
}
.c-speaker-list__title-mobile {
  font-size: $fontLarger;
  margin-bottom: $spacing;
}

.c-speaker {
  background-color: $colorWhite;
  margin-bottom: $spacing;
  display: flex;
  align-items: flex-start;
}

.c-speaker__image {
  padding-left: $spacing;
  padding-top: $spacing;
  img {
    border-radius: 50%;
  }
}

.c-speaker__content {
  flex-grow: 1;
  padding: $spacing;
  display: flex;
  flex-direction: column;
}

.c-speaker__position {
  margin: 0;
}

.c-speaker__introduction {
  margin: $spacing 0 0 0;
}

@media screen and (max-width: 59em) {
  .c-speaker {
    flex-direction: column;
    align-items: center;
  }
  .c-speaker__title {
    align-self: center;
  }
  .c-speaker__position {
    align-self: center;
  }
}

@media screen and (min-width: 60em) {
  .c-speaker-list {
    padding: $spacingLarger 0;
  }

  .c-speaker__content {
    padding: $spacingLarger $spacingLarge $spacingLarge $spacingLarge;
  }
}
