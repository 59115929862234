.c-renblad {
  .c-companyComments {
    margin-top: $spacing;

    margin-bottom: $spacing;
    padding: $spacing $spacingSmall $spacingSmall;
    max-width: 60rem;
    border-left: 10px solid #e0e0e0;

    .ownCompanyComments {
      p#publiseringsDato {
        margin-top: 0;
      }
    }

    .no-notes {
      line-height: 1rem;
      margin: 0;
    }

    h2 {
      font-size: 1.5rem;
      margin-bottom: $spacingSmall;
    }

    h4 {
      font-size: 1.2rem;
      margin-top: $spacing;
    }
  }
}
