.c-document-listing--padding-large {
  padding: var(--space-large) 0;
}

.c-document-listing__title {
  font-size: var(--heading-large-font-size);
}

.c-document-listing__buttons {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  align-items: center;
  gap: var(--space-small);
  margin-bottom: var(--space-large);
  a {
    border-radius: var(--border-radius);
    border: 1px solid var(--black-color);
    background: var(--grey-sky-color);
    padding: var(--space-xx-small) var(--space-small);
    color: var(--black-color);
  }
  a.active {
    background: var(--black-color);
    color: var(--white-color);
  }
}

.c-document-listing__top-text {
  font-size: var(--caption-font-size);
  font-weight: 400;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.c-document-listing__with-lede {
  max-width: var(--max-content-width);
  margin-inline: auto;
}

.c-document-published-date {
  margin-top: var(--space-large);
}

.select_year {
  display: flex;
  height: var(--space-small);
  align-items: center;
  gap: var(--space-small);
  margin-bottom: var(--space-small);
  margin-top: var(--space-small);

  a {
    border-radius: var(--border-radius-default);
    padding: var(--space-xx-small) var(--space-small);
    color: var(--button-color-text-secondary-default);
    border: var(--dimension-border-default) solid
      var(--button-color-border-secondary-default);
    background-color: var(--button-color-background-secondary-default);
  }
  a.selected {
    background-color: var(--button-color-background-primary-default);
    color: var(--button-color-text-primary-default);
    border: var(--dimension-border-default) solid
      var(--button-color-background-primary-default);
    &:hover {
      background-color: var(--button-color-background-primary-hover);
      color: var(--button-color-text-primary-hover);
      border: var(--dimension-border-default) solid
        var(--button-color-background-primary-hover);
    }
  }

  a:hover {
    background: var(--button-color-background-secondary-hover);
    color: var(--button-color-text-secondary-hover);
    border: var(--dimension-border-default) solid
      var(--button-color-border-secondary-hover);
    text-decoration: underline;
  }
}

.year-buttons {
  display: flex;
  gap: var(--space-small);
}

.year_dropdown_btn {
  display: flex;
  align-items: center;
  gap: var(--space-xx-small);
  padding: var(--space-xx-small) var(--space-small);
  border: var(--dimension-border-default) solid
    var(--button-color-border-secondary-default);
  border-radius: var(--border-radius-default);
  background-color: var(--button-color-background-secondary-default);
  color: var(--button-color-text-secondary-default);
  cursor: pointer;
  font-size: var(--font-small);
  font-weight: 400;

  svg {
    width: var(--space-x-small);
    height: var(--space-x-small);
    transition: transform 0.3s ease;
  }

  &.open {
    svg {
      transform: rotate(180deg);
    }
  }

  &:hover {
    background-color: var(--button-color-background-secondary-hover);
    color: var(--button-color-text-secondary-hover);
    text-decoration: underline;
    border: var(--dimension-border-default) solid
      var(--button-color-border-secondary-hover);
  }
}

.year_dropdown_container {
  position: relative;
  display: inline-block;
  outline: 0;
  &:focus {
    outline: 0 !important;
  }

  .year_dropdown_item {
    display: block;
    border-radius: 0;
    border: none;

    &.selected {
      background-color: var(--color-feedback-layer-positive);
      color: var(--black-color);
      border: none;

      &:hover {
        color: var(--black-color);
        background-color: var(--color-feedback-layer-positive);
        text-decoration: underline;
        border: none;
        border-bottom: 1px solid var(--button-color-border-secondary-default);
      }
    }

    &:hover {
      background-color: var(--color-feedback-layer-positive);
      color: var(--black-color);
      text-decoration: underline;
      border: none;
    }
    &:not(:last-child) {
      border-bottom: 1px solid var(--button-color-border-secondary-default);
    }
  }

  .year_dropdown_menu {
    margin-top: 2px;
    border-radius: var(--border-radius-default);
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border: 1px solid var(--button-color-border-secondary-default);
  }
}

@media screen and (max-width: 60em) {
  .year-buttons {
    display: none;
  }
}

@media screen and (min-width: 60em) {
  .year_dropdown_container {
    display: none;
  }
}
