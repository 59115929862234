.c-card-link {
  box-sizing: border-box;
  height: 100%;
  min-height: 10em;
  padding: $spacing;
  transition: transform 0.15s ease-in-out;
  display: flex;
  align-items: stretch;
}

.c-card-link__parent {
  width: 100%;
  position: relative;
}

.c-card-link__parent--no-illustration {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .c-card-link__arrow {
    position: relative;
    display: flex;
    justify-content: flex-end;
  }
}

.c-card-link__content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  flex-grow: 1;
  align-content: flex-start;
  h3 {
    color: $colorPineGreen;
  }
}

.c-card-link__content--side-illu {
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
  .c-card-link__image {
    margin-bottom: $spacingLarge;
  }
}

.c-card-link__content--under-illu {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-flow: column nowrap;
  align-content: flex-start;
}

.c-card-link__text {
  flex-grow: 1;
  color: $colorPineGreen;
}

.c-card-link__text--list {
  height: max-content;
  word-break: break-word;
}

@media (max-width: 40em) {
  .c-card-link {
    margin-top: 0;
  }
  .c-card-link__text,
  .c-card-link__text--list {
    h3 {
      font-size: $fontLarge;
    }
  }
}

.c-card-link__image {
  height: fit-content;
  width: fit-content;
}

.c-card-link__list {
  width: 100%;
}

.c-card-link__arrow {
  position: absolute;
  right: 0;
  bottom: 0;
  color: $colorPineGreen;
}
