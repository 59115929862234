.c-footer {
  background-color: $colorDarkPine;
  color: $colorWhite;

  font-weight: 300;
}

.c-footer {
  a:link,
  a:visited {
    color: $colorBrightGreen;
    border-bottom: 2px solid currentColor;
  }
  a:hover {
    font-weight: 500;
  }
}

.c-footer__some-link {
  margin-bottom: $spacingSmall;

  a:link,
  a:visited {
    color: $colorWhite;
    border-bottom: none;
  }
  a:hover {
    text-decoration: underline;
  }
}

.c-footer__some-icon {
  margin-right: $spacingSmall;
  vertical-align: middle;
}

a:link.c-footer__newsletter,
a:visited.c-footer__newsletter {
  color: $colorPineGreen;
  font-weight: 500;
}

.c-footer__arrow {
  margin-left: 10px;
  vertical-align: sub;
  color: $colorPineGreen;
}

.c-footer__newsletter {
  background-color: $colorWhite;
  font-size: 0.9rem;
  padding: 12px 24px 12px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}
.c-footer__newsletter:hover {
  background-color: $colorLuminousGreen;
  .c-footer__arrow {
    color: $colorPineGreen;
  }
}

@media screen and (max-width: 60em) {
  .c-footer__header {
    font-size: $fontLarge;
  }
  .c-footer__newsletter {
    padding: 12px 24px;
    svg {
      margin-bottom: -4px;
    }
  }
}
