.c-checklist-type-filter {
  padding: $spacingSmall;
  padding-left: 0.7em;
  padding-right: 2em;
  border-radius: 5px;
  margin-right: $spacingSmall;
  margin-bottom: $spacingSmall;
  box-sizing: border-box;
  cursor: pointer;
  background-color: $colorWhite;
  transition: background-color 0.3s ease;
  min-height: 25px;
  font-size: $fontSmall;
  color: $colorPineGreen;
  border: solid 1px $colorWhite;
}

.c-checklist-type-filter-wrapper {
  display: inline-flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.c-checklist-type-filter input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  min-width: 0;
}

.c-checklist-type-filter--focused {
  outline: 1px dotted Highlight;
  outline: 5px auto -webkit-focus-ring-color;
}

.c-checklist-type-filter--checked {
  background-color: $colorBrightGreen !important;
}

.c-checklist-type-filter::before {
  content: " ";
  line-height: 0.9rem;
  width: 1.3em;
  display: inline-block;
}

.c-checklist-type-filter--checked::before {
  content: "✓";
}

.c-checklist-type-filter:hover {
  background-color: $colorLuminousGreen;
}
