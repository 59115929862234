.center-on-page {
  display: grid;
  height: 100vh;
  align-content: center;
  justify-content: center;
}
.skip-link {
  color: var(--black-color);
  background-color: var(--pistacchio-color);
  border: 0.2rem solid var(--pine-green-color);
  border-top: 0;
  border-radius: 5px;
  position: absolute;
  left: 50%;
  z-index: 9999;
  padding: var(--space-xx-small) var(--space-x-small);
  transform: translateY(-100%);
  transition: transform 0.3s;

  &:focus {
    transform: translateY(0%);
  }
}

.u-link-with-arrow {
  font-size: var(--body-link-font-size);
  color: var(--black-color);
  display: flex;
  align-items: center;
  gap: var(--space-x-small);
  a {
    color: var(--black-color);
    text-decoration: underline;
  }
  a:hover {
    font-weight: 400;
  }
  svg {
    height: var(--space-small);
    width: var(--space-small);
  }
}

.u-typography {
  ul {
    list-style: disc;
    padding-left: var(--space-medium);

    li {
      font-size: var(--body-font-size);
      line-height: var(--body-line-height);
      font-weight: 300;
    }
  }
}

.svg-and-span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--space-xx-small);
}

/** HOVER & FOCUS **/

.hover-focus {
  transition: background-color 0.15s ease-in-out;
}

a:has(.hover-focus):focus {
  outline: var(--outline-stroke);

  h3 {
    text-decoration: underline;
  }
}

a:has(.hover-focus):focus .hover-focus {
  background-color: var(--grey-sky-color);
  outline: var(--outline-stroke);
  outline-offset: 1px;
}

.hover-focus:hover {
  background-color: var(--grey-sky-color);

  h3 {
    text-decoration: underline;
  }
}

.hover-focus:focus {
  background-color: var(--grey-sky-color);
  outline: var(--outline-stroke);
  h3 {
    text-decoration: underline;
  }
}

// On sections with a white bg-col item bg-col needs to be white on hover/focus
.u-bg--white {
  .hover-focus {
    &:hover {
      background-color: var(--white-color);
    }
  }
  a:has(.hover-focus):focus .hover-focus{
    background-color: var(--white-color);
    outline: var(--outline-stroke);
  }
}

// Same applies to the inverse of the above
.u-bg--grey-sky,
.u-bg--lightestGrey,
.u.u-bg--lightgrey {
  .hover-focus {
    &:hover {
      background-color: var(--white-color);
    }
  }
  a:has(.hover-focus):focus .hover-focus{
    background-color: var(--white-color);
  }
}

/** Background Color **/

.u-bg--darkGreen,
.u-bg--pine-green {
  background-color: var(--pine-green-color);
}
.u-bg--white {
  background-color: var(--white-color);
}

.u-bg--yellow,
.u-bg--sunny {
  background-color: var(--sunny-color);
}
.u-bg--light-yellow,
.u-bg--vanilla {
  background-color: var(--vanilla-color);
}
.u-bg--darkgrey {
  background-color: var(--dark-clouds-color);
}

.u-bg--lightgrey,
.u-bg--lightestGrey,
.u-bg--grey-sky {
  background-color: var(--grey-sky-color);
}

.u-bg--primaryColor {
  background-color: var(--primary-color);
}
.u-bg--primaryHover {
  background-color: var(--secondary-color);
}
.u-bg--primaryColorHover {
  background-color: var(--primary-color);
  &:hover {
    background-color: var(--secondary-color);
  }
}

.u-bg--secondaryColor {
  background-color: var(--tertiary-color);
}
.u-bg--secondaryHover {
  background-color: var(--tertiary-shade);
}
.u-bg--secondaryColorHover {
  background-color: var(--tertiary-color);
  &:hover {
    background-color: var(--tertiary-shade);
  }
}

/** Padding **/
.u-padding--tiny {
  padding: $spacingTiny;
}
.u-padding--small {
  padding: $spacingSmall;
}

.u-padding {
  padding: $spacing;
}

.u-padding-top--small {
  padding-top: $spacingSmall;
}
.u-padding-top {
  padding-top: $spacing;
}

.u-padding-top--large {
  padding-top: $spacingLarge;
}
.u-padding-top--larger {
  padding-top: $spacingLarger;
}

.u-padding-top--huge {
  padding-top: $spacingHuge;
}

.u-padding-bottom {
  padding-bottom: $spacing;
}

.u-padding-bottom--small {
  padding-bottom: $spacingSmall;
}

.u-padding-bottom--large {
  padding-bottom: $spacingLarge;
}
.u-padding-bottom--larger {
  padding-bottom: $spacingLarger;
}

.u-padding-top-bottom--large {
  padding-top: $spacingLarge;
  padding-bottom: $spacingLarge;
}
.u-padding-top-bottom--larger {
  padding-top: $spacingLarger;
  padding-bottom: $spacingLarger;
}
.u-padding-top-bottom {
  padding-top: $spacing;
  padding-bottom: $spacing;
}
.u-padding-top-bottom--small {
  padding-top: $spacingSmall;
  padding-bottom: $spacingSmall;
}
.u-padding-left {
  padding-left: $spacingLarge;
}

.u-padding-right {
  padding-right: $spacingLarge;
}

.u-padding-sides {
  padding-left: $spacingLarge;
  padding-right: $spacingLarge;
}
/** Margin **/
.u-margin--rm {
  margin: 0;
}
.u-margin--rm-top * {
  margin-top: 0;
}
.u-margin--rm-bottom {
  margin-bottom: 0;
}
.u-margin-top,
.u-margin-top-bottom {
  margin-top: $spacing;
}
.u-margin-bottom,
.u-margin-top-bottom {
  margin-bottom: $spacing;
}
.u-margin-top--small,
.u-margin-top-bottom--small {
  margin-top: $spacingSmall;
}
.u-margin-bottom--small,
.u-margin-top-bottom--small {
  margin-bottom: $spacingSmall;
}
.u-margin-top--large,
.u-margin-top-bottom--large {
  margin-top: $spacingLarge;
}
.u-margin-top--larger,
.u-margin-top-bottom--larger {
  margin-top: $spacingLarger;
}
.u-margin-bottom--large,
.u-margin-top-bottom--large {
  margin-bottom: $spacingLarge;
}

@media (min-width: 40em) {
  .u-margin-right-desktop--larger {
    margin-right: $spacingLarger;
  }
}

@media all and (-ms-high-contrast: none) {
  .u-hide-on-ie {
    display: none;
  }
  .u-space-on-ie {
    padding-bottom: 20%;
  }
}

.u-relative {
  position: relative;
}

.u-block {
  display: block;
}
.u-width-inherit {
  max-width: inherit;
}
.u-max-width-full {
  max-width: 100%;
}
.u-vertical-align__middle {
  vertical-align: middle;
}

/* Hide */
.u-hide {
  display: none;
}

.u-flex {
  display: flex;

  @media screen and (max-width: 60em) {
    flex-direction: column;
  }
}

.u-flex-space-between {
  justify-content: space-between;
}

.u-flex-align--center {
  align-items: center;
}

.u-flex-basis--third {
  flex-basis: 33%;
}

.u-flex-column {
  display: flex;
  flex-direction: column;
}

.u-flex--grow {
  flex-grow: 1;
}
/* 404 page */
.c-404__illustration {
  svg {
    width: 100%;
  }
}
.u-rotate {
  > svg {
    transform: rotate(180deg);
    transform-origin: 50% 50%;
  }
}

.u-menu-overlay-container {
  overflow-y: hidden;
}

.u-sticky--top {
  position: sticky;
  /* with top: -1px we can use IntersectionObserver to know when an element is stuck */
  top: -1px;
  z-index: z-index(top);
}

.u-sticky--top-left {
  position: sticky;
  left: 0px;
  top: 100px;
  z-index: z-index(navigation);
}

/* move rules to last step in order to defeat specificity problems and have them always valid */
@media (min-width: 60em) {
  .u-hide--desktop {
    display: none;
  }
}
@media (max-width: 59.99em) {
  .u-hide--mobile {
    display: none;
  }
}
.u-inline-flex {
  display: inline-flex;
}

/* svg-helpers */

.u-svg-color--theme {
  svg {
    * {
      stroke: var(--primary-color);
    }
    &:hover * {
      stroke: var(--secondary-color);
    }
  }
}

.u-svg-color--white {
  svg {
    /* more specificity to override path-stroke if present */
    * {
      stroke: $colorWhite;
    }
  }
}

.u-svg-color--white-hover {
  svg {
    /* more specificity to override path-stroke if present */
    * {
      stroke: $colorWhite;
    }
    &:hover * {
      stroke: $colorFog;
    }
  }
}

/* visually hidden */
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  z-index: z-index(top);
}

.u-block {
  display: block;
}
