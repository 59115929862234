.c-card-employee-listing {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  .c-card-employee-listing--card {
    margin-top: $spacingLarge;
  }

  .c-card-employee-listing__credentials {
    width: 100%;
  }

  ul.c-card-employee-listing__list {
    margin: 0;
    padding: 0;
    max-width: 100%;
    list-style: none;
  }
  .c-card-employee-listing__responsibilities {
    margin: 0;
  }
}

@media (max-width: 39.99em) {
  .c-card-employee-listing__body {
    margin-bottom: $spacingLarge;
  }

  .c-card-employee-listing--card {
    margin-bottom: $spacingLarge;
  }
}

@media (min-width: 40em) {
  .c-card-employee-listing--card {
    flex: 0 1 45%;
    padding-bottom: $spacingLarge;
  }
}

.c-card-employee-listing__head {
  display: flex;
  margin-bottom: $spacing;
}

.c-card-employee-listing__list {
  margin: 0;
  padding: 0;
}

.c-card-employee-listing__list-item {
  &::before {
    display: none !important;
  }
  &:first-child {
    margin-top: 0;
  }
}

.c-card-employee-listing__credentials {
  padding-left: $spacing;
  h3 {
    line-height: 1.7;
    color: $colorPineGreen;
    margin: 0;
  }
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
  li {
    margin: 0;
    line-height: 1.7;
  }
}

.c-card-employee-listing__body {
  margin-bottom: 0;
  display: flex;
  flex-flow: row wrap;
  flex-basis: 100%;
  width: 100%;
}
.c-card-employee-listing__responsibilities-title {
  margin-top: $spacing;
  margin-bottom: 0;
  color: $colorPineGreen;
  //font-size: $fontTiny;
  //text-transform: uppercase;
  //letter-spacing: .05em;
  font-weight: 400;
}

.c-card-employee-listing__responsibilities {
  font-weight: 400;
}
