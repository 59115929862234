.c-masthead .top-nav {
  display: flex;
  justify-content: space-between;
  padding: $spacingSmall $spacing;
  background-color: #fff;
}

.c-masthead-header {
  background-color: $colorSunny;
  color: $colorPineGreen;
  padding: $spacing;

  &__title {
    line-height: 1.4;
    font-weight: 500;
  }
}
