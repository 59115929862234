.c-submenu {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
}

.c-submenu__item {
  border-left: solid 5px $colorFog;
  margin-top: 0;
  margin-bottom: 0;
  padding: 10px 12px;
}

.c-submenu__item button {
  color: $colorPineGreen;
  font-size: $font;
  font-weight: 300;
}
.c-submenu__item button:hover {
  font-weight: 500;
}

.c-submenu__item--active {
  button {
    font-weight: 500;
  }
  border-left: solid 5px $colorBrightGreen;
}
.c-submenu__item--active button:hover {
  color: $colorPineGreen;
}

@media (min-width: 45em) {
  .c-submenu {
    display: flex;
    border-bottom: solid 5px $colorFog;
  }
  .c-submenu__item {
    border-left: none;
    border-bottom: solid 5px $colorFog;
    margin-bottom: -5px;
    margin-right: $spacingLarge;
    padding: $spacingTiny;
  }
  .c-submenu__item--active {
    border-bottom: solid 5px $colorBrightGreen;
  }
}
