.c-card-major--fullheight {
  background-color: $colorWhite;
  transition: transform 0.15s ease-in-out;
  display: flex;
  flex-direction: column;
  min-height: 25em;
}

.c-card-major {
  flex-grow: 1;
  background-color: $colorWhite;
  padding: $spacing $spacingLarge;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.c-card-major--image {
  max-width: 100%;
  height: auto;
}

.c-card-major--fullheight:hover {
  transform: translate($spacing/4, $spacingNegative/4);
}

.c-card-major__top-bar {
  display: flex;
  justify-content: space-between;
}

.c-card-major__top-bar-tag {
  background-color: $colorBrightGreen;
  font-size: $fontSmall;
  padding-right: $spacing;
  padding-left: $spacing;
  margin: 0;
}

.c-card-major__top-bar-date {
  font-size: $fontSmall;
  margin: 0;
}

.c-card-major__info--margin {
  margin-bottom: $spacingSmall;
  h3 {
    word-break: keep-all;
  }
}

.c-card-major__arrow {
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 60em) {
  .c-card-major__info--margin {
    h3 {
      font-size: $fontLarge;
    }
  }
}

@media all and (-ms-high-contrast: none) {
  .c-card-major--image {
    max-height: 20em;
  }
}
