.c-header__title {
  line-height: 1.4;
  font-weight: 500;
}

.c-header {
  padding-top: $spacingLarge;
  padding-bottom: $spacingLarge;
  min-height: 110px;
  &__form-button {
    margin-bottom: -$spacingLarge;
  }

  &__inner {
    max-width: var(--max-content-width);
    margin-inline: auto;
  }
}

.c-header--slim {
  min-height: 0;
  padding-top: $spacingSmall;
  padding-bottom: $spacingSmall;

  .c-header__title {
    padding-top: $spacingTiny;
  }

  .c-header__lede {
    margin-top: $spacingSmall;
    margin-bottom: $spacingSmall;
  }
}

.c-header--default,
.c-header--event {
  background-color: $colorPineGreen;
  color: $colorWhite;
}

.c-header--white {
  color: $colorPineGreen;
}

.c-header--yellow {
  background-color: $colorSunny;
  color: $colorPineGreen;
}

.c-header__lede {
  font-size: $fontLarger;
  line-height: 1.5;
  a {
    text-decoration: underline;
    color: $colorPineGreen;
  }
  margin: 0;
  margin-top: $spacing;
  margin-bottom: $spacing;
}

.c-header--white,
.c-header--yellow {
  .c-header__lede {
    font-size: $fontLarge;
    line-height: 1.5;
    font-weight: 400;
  }
}

.c-header__with-illustration {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 2rem;

  .illustration {
    align-self: center;
    img {
      width: 100%;
    }
  }
}

@media (min-width: 60em) {
  .c-header__title {
    padding-top: $spacingLarge;
  }
  .c-header--event {
    .c-header__inner {
      max-width: unset;
    }
    .c-header__title {
      flex-basis: 54%;
      max-width: 54%;
    }
  }
}

@media (max-width: 60em) {
  .c-header {
    padding: $spacing 0;
    &__lede {
      font-size: $fontLarge;
    }
    &__form-button {
      margin-bottom: -$spacing;
    }
    &__inner {
      // Used to move title down on Event pages.
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }
}
