.c-text-block__with-image {
  display: grid;
  gap: 2em;
  align-items: center;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: 59.99em) {
    grid-template-columns: 1fr;
  }

  .text {
    order: 1;
  }
  .image {
    order: 2;

    img {
      object-fit: contain;
      width: 100%;
    }
  }

  &.image-first {
    .text {
      order: 2;
    }
    .image {
      order: 1;
    }
  }

  &.third {
    grid-template-columns: 1fr 2fr;

    @media screen and (max-width: 59.99em) {
      grid-template-columns: 1fr;
    }
  }

  &.two-thirds {
    grid-template-columns: 2fr 1fr;

    @media screen and (max-width: 59.99em) {
      grid-template-columns: 1fr;
    }
  }
}
