.c-benefits {
  padding: $spacing;
  h2 {
    font-size: $fontLarger;
    color: $colorDarkPine;
  }

  ul {
    margin-left: $spacingSmall;
  }

  ul li {
    list-style-image: url("/asset/icons/green-checkmark.svg");
    line-height: 1.5;
  }
}

.c-benefits__green {
  background-color: $colorPistacchio;
}

.c-benefits__yellow {
  background-color: $colorVanilla;
}
