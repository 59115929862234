.c-title-block {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  position: relative;
  gap: $spacingSmall;

  @media screen and (max-width: 50em) {
    grid-template-columns: 1fr;
  }

  &__text {
    h2 {
      font-size: $fontLarger;
      color: $colorDarkPine;
    }

    .c-portableText p {
      font-size: $fontLarge;
    }
  }

  &__image {
    max-width: 100%;
    &.absolute-position {
      height: 110%;
      max-width: 110%;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    @media screen and (max-width: 50em) {
      object-fit: contain;
      width: 100%;

      &.hide-on-mobile {
        display: none;
      }
    }
  }
}
