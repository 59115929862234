@import "../_base.scss";

.o-grid {
  display: flex;
  flex-wrap: wrap;
}

.o-grid__item {
  box-sizing: border-box;
  flex: 1;
  flex-basis: 100%;
  max-width: 100%;
}

.o-grid__item--evenheight {
  height: 100%;
  box-sizing: border-box;
}

.o-grid--alternate .o-grid:nth-child(2n) {
  flex-direction: row-reverse;
}

@media (max-width: 59.99em) {
  .o-grid__column-reverse--mobile {
    flex-direction: column-reverse;
  }
}
@media (min-width: 40em) {
  .o-grid__desktop-flex-reverse {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
}

@media (min-width: 60em) {
  .o-grid__item--full {
    flex-basis: 100%;
    max-width: 100%;
  }

  .o-grid__item--half {
    max-width: 50%;
  }

  /* fix image and wrapping in IE11 */
  @supports not (-ms-high-contrast: none) {
    .o-grid__item--half {
      flex-basis: 50%;
    }
  }

  .o-grid__item--min-half {
    flex-basis: 50%;
  }

  .o-grid__item--third {
    flex-basis: 50%;
    max-width: 50%;
  }

  .o-grid__item--two-thirds {
    flex-basis: 50%;
    max-width: 50%;
  }

  .o-grid__item--fourth {
    flex-basis: 50%;
    max-width: 50%;
  }
}

@media (min-width: 1000px) {
  .o-grid__item--third {
    flex-basis: 33.33%;
    max-width: 33.33%;
  }
  .o-grid__item--two-thirds {
    flex-basis: 66.66%;
    max-width: 66.66%;
  }

  .o-grid__item--fourth {
    flex-basis: 25%;
    max-width: 25%;
  }

  .o-grid__item--half-large {
    max-width: 50%;
  }
}

.o-grid__item--margin {
  margin: $spacing;
}

.o-grid__item--margin-small {
  margin: $spacingSmall;
}

.o-grid__item--margin-larger {
  margin: $spacingLarger;
}

.o-grid__item--margin-side {
  margin: 0 $spacing;
}

.o-grid__item--margin-top-bottom {
  margin: $spacing 0;
}

.o-grid__item--margin-top {
  margin-top: $spacing;
}

.o-grid__item--margin-top-negative {
  margin-top: $spacingNegative * 3;
}

.o-grid__item--margin-top-huge {
  margin-top: $spacingHuge;
}

@media (min-width: 60em) {
  .o-grid__item--margin {
    margin: $spacingLarge;
  }
  .o-grid__item--margin-side {
    margin: 0 $spacingLarge;
  }
}

.o-grid__item--padding {
  padding: $spacing;
}

.o-grid__item--padding-small {
  padding: $spacingSmall;
}

.o-grid__item--padding-large {
  padding: $spacingLarge;
}

.o-grid__item--padding-less {
  padding: $spacing $spacingLarge;
}

.o-grid__item--padding-side {
  padding: 0 $spacingLarge;
}

.o-grid__top {
  align-items: flex-start;
}

.o-grid__end {
  justify-content: flex-end;
}

.o-grid__self-end {
  justify-self: flex-end;
}

.o-grid__center {
  align-items: center;
}

.o-grid__bottom {
  align-items: flex-end;
}
.o-grid__stretch {
  align-items: stretch;
}
.o-grid__self-center {
  align-self: center;
}

.o-grid__around {
  justify-content: space-around;
}

.o-grid__between {
  justify-content: space-between;
}

.o-grid--nowrap {
  flex-wrap: nowrap;
}
