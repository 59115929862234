/* Core Styles */

// To leverage the native smooth-scrolling by the browser (currently available in Firefox 36+ and Chrome 49+)
// body, .smooth-container { scroll-behavior: smooth }

.toc {
  overflow-y: auto;
}

.c-toc__content > .toc-list {
  border-left: 2px solid #eee;
}

.toc > .toc-list {
  overflow: hidden;
  position: relative;

  li {
    list-style: none;
  }
}

.js-toc {
  overflow-y: hidden;
}

.toc-list {
  margin: 0;
  padding-left: 10px;
}

.toc-list-item > ol.toc-list {
  padding-left: 1rem;
}

a.toc-link {
  color: currentColor;
  height: 100%;
  cursor: pointer;
}

.is-collapsible {
  max-height: 1000px;
  overflow: hidden;
  transition: all 300ms ease-in-out;
}

.is-collapsed {
  max-height: 0;
}

.is-position-fixed {
  position: fixed !important;
  top: 0;
}

.is-active-link {
  font-weight: 500;
}
