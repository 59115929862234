.c-call-action {
  display: flex;
  background-color: $colorWhite;
  justify-content: space-between;
}

.c-call-action__body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: $spacingLarge;
}

.c-call-action__contact {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $colorBlack;
}

.c-call-action__contact-text {
  margin-left: $spacing;
}

.c-call-action__image {
  max-width: 75%;
  max-height: 15em;
}

@media (max-width: 60em) {
  .c-call-action {
    flex-direction: column-reverse;
  }

  .c-call-action__body {
    padding: $spacing $spacing 0 $spacing;
  }

  .c-call-action__image {
    max-width: 100%;
  }
}
