.c-portableText {
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: $colorPineGreen;
    font-weight: 500;
    margin: $spacingLarge 0 $spacing;
    a {
      color: $colorBlack;
    }
    a:hover {
      text-decoration: underline;
    }
  }

  &.edit-mode-active {
    h2 {
      margin-top: 0;
    }
  }

  strong {
    font-weight: 500;
  }
  p:empty {
    height: 2em;
  }

  ul,
  ol {
    margin-top: $spacing;
    margin-bottom: $spacing;
    padding-inline-start: 2em;
    font-weight: 300;
  }

  ul {
    list-style: disc;
  }

  li > ul {
    list-style: circle;
  }

  ul > li,
  ol > li {
    margin-top: 0;
    line-height: 1.7;
  }

  li {
    font-size: 1em;
    margin-top: $spacingSmall;
    margin-bottom: $spacingSmall;
  }

  li > p {
    margin: 0;
    margin-bottom: $spacingSmall;
  }

  li > p:first-child {
    display: inline-block;
  }

  li > p:not(:first-child) {
    margin-left: 1em;
  }

  &--renblad {
    h1 {
      font-size: 2.25rem;
      line-height: 125%;
      font-weight: 500;
      color: var(--primary-color);
    }
    h2 {
      font-size: 1.875rem;
      line-height: 125%;
      font-weight: 500;
      color: var(--primary-color);
    }
    h3 {
      font-size: 1.375rem;
      font-weight: 500;
      line-height: 125%;
      color: $colorBlack;
    }
    h4 {
      font-size: 1.125rem;
      font-weight: 500;
      line-height: 125%;
      color: $colorBlack;
    }
    h5 {
      font-size: 1rem;
      font-weight: 500;
      line-height: 125%;
      color: $colorBlack;
    }
    h6 {
      font-size: 1rem;
      line-height: 125%;
      font-weight: 300;
    }
    li > p:first-child {
      display: block;
    }
  }
}

.c-portableText--image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

.c-portableText--image-wrapper {
  margin: $spacingLarger auto;
  width: fit-content;

  figcaption {
    font-weight: 300;
    margin-top: $spacingSmall;
  }
}

.c-portableText__button {
  margin-top: $spacing;
}

.c-portableText__link {
  &:link,
  &:visited {
    color: $colorPineGreen;
    text-decoration: underline;
  }
  &:hover {
    font-weight: 500;
    text-decoration: underline;
  }
}

.c-portableText__video-wrapper {
  padding: 56.25% 0 0 0;
  position: relative;
}

.c-portableText__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-portableText--download-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.c-download--wrapper {
  width: 100%;
  margin-bottom: $spacing;
  justify-content: space-between;
  background-color: $colorFog;

  h3 {
    margin: $spacing 0 $spacing;
    color: $colorBlack;
  }

  @media (min-width: 40em) {
    display: flex;
    flex-basis: 49%;
  }

  @media (min-width: 60em) {
    flex-basis: 32%;
  }
}

.c-download__image-wrapper {
  width: 30%;

  @media (min-width: 40em) {
    padding: $spacing $spacing 0 $spacing;
    width: auto;
  }
}

.c-download__image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  max-height: 250px;

  @media (min-width: 40em) {
    object-fit: contain;
    object-position: left top;
  }
}

.c-download__content {
  flex: 49%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: $spacing;
  padding-left: $spacing;
  padding-right: $spacing;
}

.c-download__body {
  display: flex;
  height: 100%;

  @media (min-width: 40em) {
    flex-direction: column;
  }
}

.c-download__link-and-icon {
  display: flex;
}

.c-download__border {
  width: fit-content;
}

.c-download__contact {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $colorPineGreen;
  border-bottom: 1px solid currentColor;
  margin-right: $spacingSmall;
}

.c-download__contact-text {
  margin-left: $spacing;
}

.c-portableText--cta-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.c-cta--wrapper {
  display: flex;
  margin-bottom: $spacing;
  flex-basis: 49%;
  justify-content: space-between;
  background-color: $colorSunny;

  h3 {
    margin: $spacingLarge 0 $spacing;
  }

  @media (max-width: 60em) {
    flex-basis: 100%;
    flex-direction: column-reverse;
  }
}

.c-cta--wrapper:only-child {
  flex-basis: 100%;
  flex-direction: column-reverse;

  .c-cta__body {
    @media (min-width: 60em) {
      flex-direction: row-reverse;
    }
  }
  .c-cta__content {
    @media (min-width: 40em) {
      padding-left: $spacingLarge;
    }
  }
  .c-cta__image-wrapper {
    flex-direction: row-reverse;

    @media (min-width: 60em) {
      width: auto;
    }
  }
}

.c-cta__image-wrapper {
  width: 35%;

  @media (min-width: 40em) {
    width: 50%;
  }
}

.c-cta__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.c-cta__content {
  flex: 49%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 $spacing $spacing $spacing;

  @media (min-width: 40em) {
    padding-left: $spacingLarge;
  }
}

.c-cta__body {
  display: flex;
  height: 100%;
}

.c-cta__border {
  width: fit-content;
}

.c-cta__contact {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $colorPineGreen;
  border-bottom: 2px solid currentColor;
}

.c-cta__contact-text {
  margin-left: $spacing;
}

.c-nb-textbox {
  padding: $spacing;
  margin: $spacing;

  &__portable-text {
    font-size: $fontLarge;
  }

  &__portable-text-center {
    text-align: center;
  }

  &__pine {
    background: $colorDarkPine;
    color: $colorWhite;
  }

  &__sunny {
    background: $colorSunny;
    color: $colorDarkPine;
  }

  &__magenta {
    background: $colorMagenta;
    color: $colorWhite;
  }
}
