.c-column-blocks {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: $spacing;

  .c-column-block {
    background-color: $colorWhite;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    h3 {
      margin-bottom: $spacing;
    }

    h3,
    .c-portableText {
      padding: $spacingSmall;
    }

    .c-portableText {
      height: 100%;
    }

    img {
      object-fit: contain;
      width: 100%;
    }
  }

  @media screen and (max-width: 59.99em) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 39.99em) {
    grid-template-columns: 1fr;
  }

  &.fifty-fifty {
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: 39.99em) {
      grid-template-columns: 1fr;
    }
  }

  &.first-double {
    grid-template-columns: 2fr 1fr;

    @media screen and (max-width: 59.99em) {
      grid-template-columns: 2fr;
    }

    @media screen and (max-width: 39.99em) {
      grid-template-columns: 1fr;
    }
  }

  &.second-double {
    grid-template-columns: 1fr 2fr;

    @media screen and (max-width: 59.99em) {
      grid-template-columns: 2fr;
    }

    @media screen and (max-width: 39.99em) {
      grid-template-columns: 1fr;
    }
  }
}
