@media screen and (min-width: 60em) {
  .c-card-full {
    min-height: 15em;
  }
  .c-card-full--illustration {
    padding: $spacingSmall;
  }
}

.c-card-full {
  border: 1px solid var(--black-color);
  padding: 0 $spacingLarge $spacing;
  margin: 0 0 1em 0;
  box-sizing: border-box;
  transition: transform 0.15s ease-in-out;
  display: flex;
  align-items: stretch;
}

.c-card-full--flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.c-card-full--illustration {
  max-width: 7.5rem;
  min-width: 9em;
  display: flex;
  align-items: center;
}
.c-card-full--illustration-image {
  width: 100%;
  padding-right: 2em;
  box-sizing: border-box;
}

.c-card-full--content {
  text-decoration: none;
  color: $colorPineGreen;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.c-card-full__top-bar--yellow {
  height: var(--space-xx-small);
  width: 100px;
  background-color: $colorSunny;
  margin-block-start: 0;
  margin-block-end: var(--space-xx-small);
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.c-card-full__top-bar--green {
  height: var(--space-xx-small);
  width: 100px;
  background-color: $colorBrightGreen;
  margin-block-start: 0;
  margin-block-end: var(--space-xx-small);
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.c-card-full__top-text {
  //font-size: $fontTiny;
  //text-transform: uppercase;
  //letter-spacing: 0.05em;
  //margin: 0;
}

.c-card-full__header {
  margin-top: $spacing;
  margin-bottom: $spacing;
  color: $colorPineGreen;
}

.c-card-full__datetag {
  color: $colorBlack;
}
@media screen and (max-width: 60em) {
  .c-card-full--illustration {
    padding-top: $spacing;
  }
}

@media screen and (max-width: 40em) {
  .c-card-full__header {
    font-size: $fontLarge;
  }
  .c-card-full--flex {
    display: flex;
    flex-direction: column;
  }
  .c-card-full__top-bar--yellow {
  }
}
.c-card-full__header--bold {
  font-weight: bold;
}

.c-card-full__date-heading {
  //margin: 0;
  //text-transform: uppercase;
  //letter-spacing: 0.05em;
  //font-size: $fontTiny;
}

.c-card-full__arrow {
  color: $colorPineGreen;
}
