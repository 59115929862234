.c-renblad-series-card {
  overflow-y: hidden;
  height: auto;

  h3 {
    font-size: 1em;
    margin-top: 0.5rem;
  }

  li {
    font-size: 0.85em;
    text-align: start;
  }

  @media (min-width: 620px) {
    &.c-icon-filter {
      width: 180px;
      height: 190px;
    }
  }
}
