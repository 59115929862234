.c-image-carousel {
  margin-bottom: var(--space-050);
  .c-portableText--image-wrapper {
    margin-bottom: var(--space-050);
  }
}

.c-image-carousel-ui {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  small {
    color: var(--color-text-secondary);
    font-family: var(--font-family-sans);
    font-size: var(--font-size-body-2);
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
    text-wrap: balance;
  }
  span {
    color: #000;
    font-family: var(--font-family-sans);
    font-size: var(--font-size-body-2);
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
    text-wrap: nowrap;
  }
  margin-bottom: var(--space-150);
}

.c-image-carousel__picker {
  button {
    color: var(--button-icon-color-fill-text-default);
    padding: var(--space-050);
    display: flex;
    align-items: center;
  }
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: var(--space-050);
}