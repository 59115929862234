.c-text-field {
  max-width: 500px;
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;

  label {
    align-self: stretch;
    color: var(--input-color-text-default);
    font-size: var(--font-size-body-1);
    font-style: normal;
    font-weight: var(--font-weight-regular);
    line-height: 160%; /* 28.8px */
  }
  .input-wrapper {
    border-radius: var(--button-border-radius);
    outline: 1px solid var(--color-border-default);
    background: var(--input-color-background-default);
    display: flex;
    padding: 9px 16px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;

    &:focus-within {
      outline: 1px solid var(--input-color-border-focus, #2a3e41);
      box-shadow: 0px 0px 0px 2px var(--input-color-border-focus-ring, #01c260);
    }

    input {
      flex: 1 0 0;
      color: var(--input-color-text-default, #142b2f);
      font-family: inherit;
      font-size: var(--font-size-md);
      font-style: normal;
      outline: none;
      border: none;
      font-weight: var(--font-weight-regular);
      line-height: 160%; /* 30.4px */
    }
  }
  .error-message {
    color: var(--input-color-text-error, #97043e);
    font-weight: var(--font-weight-regular, 700);
    line-height: 160%; /* 30.4px */
    display: flex;
    align-items: center;
    gap: 8px;
    p {
      margin: 0;
    }
  }
}
