.c-login-button,
.c-profile-dropdown a.c-login-button:visited,
.c-profile-dropdown a.c-login-button:link,
.c-menu__list-item a.c-login-button:link,
.c-menu__list-item a.c-login-button:visited {
  background-color: var(--plum-color);
  color: var(--white-color);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  padding: 9px 24px;

  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.1s;
  &:hover {
    background-color: var(--magenta-color);
    font-weight: 400;
    text-decoration: underline;
  }
}

.c-menu__dropdown-list li .c-login-button {
  margin-bottom: 6vh;
}
