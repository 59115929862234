.navigation-card {
  &-level-1 {
    display: block;
    padding: var(--space-medium);
    border: 1px solid var(--dark-pine-color);
    background-color: var(--bg-color);
    flex-basis: 385px;
    position: relative;
    color: var(--font-color);

    @include mobile {
      flex-basis: fit-content;
    }

    .title {
      color: var(--primary-color);
      font-weight: 500;
      margin-bottom: var(--space-small);
    }

    .subtitle {
      text-transform: uppercase;
    }

    .arrow {
      width: 20px;
      stroke: var(--pine-green-color);
      position: absolute;
      bottom: var(--space-xx-small);
    }

    &:after {
      content: "";
      position: absolute;
      top: -1px;
      left: var(--space-medium);
      width: 90px;
      height: 12px;
      background-color: var(--secondary-color);
    }
  }

  &-level-2 {
    display: block;
    padding: var(--space-small);
    border: 1px solid var(--dark-pine-color);
    background-color: var(--tertiary-color);
    flex-basis: 300px;
    position: relative;
    color: var(--font-color);

    @include mobile {
      flex-basis: fit-content;
    }

    .title {
      font-weight: 500;
    }

    .subtitle {
      text-transform: uppercase;
    }

    .arrow {
      width: 20px;
      stroke: var(--pine-green-color);
      position: absolute;
      right: var(--space-x-small);
      top: var(--space-x-small);
    }
  }
}
