.renblad-theme-debugger {
  display: flex;
  gap: $spacingSmall;
  flex-flow: row wrap;
  align-items: center;
  padding: 0 $spacing;
  background-color: $colorDarkClouds;
  color: $colorAir;
  &__setting {
    label {
      font-weight: 300;
    }
    select {
      margin: 0 $spacingSmall;
      background-color: $colorDarkClouds;
      color: $colorAir;
      border: 1px solid $colorAir;
      line-height: 1.7;
      font-size: 1rem;
      font-weight: 300;
    }
  }
}
