.breadcrumbs {
  display: flex;
  gap: var(--space-xx-small);
  margin: var(--space-x-small) 0;
  color: var(--primary-color);

  a {
    color: var(--primary-color);
    text-decoration: underline;
    text-underline-offset: 2px;
  }

  @include mobile {
    a,
    span {
      display: none;
    }
    a.mobile {
      display: inline;
    }
  }
}
