.c-callout {
    padding: var(--space-x-small) var(--space-small);
    border-radius: 4px;
    background-color: var(--color-background-default);
    margin: var(--space-x-small) 0;

   h4.c-callout-title {
        margin: 0;
    }
    * {
        margin: 0;
    }
    
    &.default {
        background-color: var(--color-layer-default-2);
        border-top: 5px solid var(--color-border-default);
        * {
            color: var(--color-text-primary);
        }
    }
    &.info { 
        background-color: var(--color-feedback-layer-informative);
        border-top: 5px solid var(--color-feedback-border-informative);
        * {
            color: var(--color-feedback-text-informative);
        }
    }
    &.success {
        background-color: var(--color-feedback-layer-positive);
        border-top: 5px solid var(--color-feedback-border-positive);
        * {
            color: var(--color-feedback-text-positive);
        }
    }
    &.warning {
        background-color: var(--color-feedback-layer-warning);
        border-top: 5px solid var(--color-feedback-border-warning);
        * {
            color: var(--color-feedback-text-warning);
        }
    }
    &.error {
        background-color: var(--color-feedback-layer-error);
        border-top: 5px solid var(--color-feedback-border-error);
        * {
            color: var(--color-feedback-text-error);
        }
    }
}