.c-comment-admin--main {
  margin-bottom: 2.2rem;
}

.c-comment-admin--rules {
  display: flex;
  flex-direction: row;
  margin: 0 -0.5em;
  margin-bottom: 1rem;

  .c-comment-admin--company-rules,
  .c-comment-admin--company-type-rules {
    margin: 0.5em 0;
    padding: 0 10px;

    h4 {
      margin-top: 0.2em;
      margin-bottom: 0.2em;

      select {
        float: right;
      }
    }

    .c-comment-admin--list {
      border: solid 1px #ccc;
      height: 200px;
      overflow-y: auto;
      clear: both;
    }
  }

  .c-comment-admin--company-rules {
    flex: 1.5;
  }

  .c-comment-admin--company-type-rules {
    flex: 1;
  }

  @media screen and (max-width: 39.99em) {
    flex-direction: column;
  }
}
