.c-profile-dropdown {
  a:link,
  a:visited {
    color: $colorPineGreen;
    text-decoration: none;
  }
  a:hover {
    color: $colorBrightGreen;
  }
}

.c-profile-button {
  display: flex;
  font-size: $fontTiny;
  align-items: center;
  color: $colorPineGreen;
  min-width: 200px;
  justify-content: space-around;
}

.c-profile-button:hover {
  color: $colorBrightGreen;
}
.c-profile-button__profileicon {
  margin-right: 10px;
}

.c-profile-button__arrowicon {
  margin-left: 10px;
}

.c-profile-open__wrapper {
  position: relative;
  z-index: z-index(above-top);
}

.c-profile-open {
  border: 1px solid $colorPineGreen;
  background-color: $colorWhite;
  padding: 0em 1em;
  margin-top: $spacingSmall;
  position: absolute;
}

.c-profile-open__list {
  margin: 0;
  padding: 0;
}
.c-profile-open__list li {
  margin-top: 1em;
  margin-bottom: 1em;
}
