.c-program-day--time {
  padding-right: $spacing;
  font-family: "Roboto";
}

.c-program-day__button {
  border: none;
  color: $colorBlack;
  width: 100%;
  text-align: left;
  background-color: $colorVanilla;
  padding-left: $spacingSmall;
  padding-right: $spacingSmall;
}

.c-program-day__button-icon {
  background-color: inherit;
  padding-left: $spacingSmall;
  padding-right: $spacingSmall;
}
.c-program-day__button-icon--collapsed {
  transform: rotate(-90deg);
}

.c-program-day--wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.c-program-day--title {
  font-family: "Roboto";
  font-size: 24px;
  display: inline-block;
  vertical-align: middle;
  margin: 10px 0;
}

.c-program-day--event__text {
  position: relative;
  &:before {
    content: "";
    display: inline-block;
    position: absolute;
    left: -10px;
    width: 2px;
    height: 100%;
    background: $colorSunny;
  }
  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    left: -14px;
    width: 11px;
    top: 28px;
    height: 11px;
    background: $colorSunny;
    border-radius: 50%;
  }
}

.c-program-day--event__text-title {
  font-family: "Roboto";
}
.c-program-day {
  margin-bottom: $spacing;
}

@media (max-width: 59.99em) {
  .c-program-day {
    background-color: $colorVanilla;
    padding: $spacingTiny $spacing;
  }
}

@media (min-width: 60em) {
  .c-program {
    background-color: $colorVanilla;
    padding: $spacing 0;
  }
}
