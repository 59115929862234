.cards-section {
  background-color: var(--tertiary-color);
  padding: var(--space-x-large) 0px;
}

.planbok-background {
  background-color: var(--tertiary-color);
}

.cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--space-small);
  margin: var(--space-x-large) auto;
  max-width: 50rem;

  @include mobile {
    flex-direction: column;
  }

  a {
    flex-grow: 1;
  }
}

.planbok-header {
  margin: var(--space-x-large) auto;
  max-width: var(--renblad-content-width);
  color: var(--primary-color);

  .title {
    color: var(--primary-color);
    font-weight: 500;
    margin-bottom: var(--space-medium);
  }

  .description {
    font-size: var(--lead-font-size);
    max-width: var(--renblad-content-width);
  }
}

.planbok {
  max-width: var(--renblad-max-width);
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  background-color: var(--white-color);

  @include no-aside {
    flex-direction: column;
    padding: 0 var(--space-x-small);
  }

  .links {
    display: flex;
    flex-direction: column;
    gap: var(--space-x-small);
    margin: var(--space-x-large) 0px;

    a {
      color: var(--primary-color);
      text-decoration: underline;
      display: flex;
      align-items: center;
      gap: var(--space-xx-small);
      font-size: var(--button-font-size);
      text-underline-offset: 6px;
      text-decoration-thickness: 2px;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.planbok-top {
  width: 100%;

  background-color: var(--tertiary-shade);

  .planbok-top-wrapper {
    display: flex;
    max-width: var(--renblad-max-width);
    margin: 0 auto;
    height: var(--space-xx-large);
    /* Position is for some unknown reason needed to make box-shadow visible. */
    position: relative;

    &.editmode-enabled {
      box-shadow: 0 var(--space-x-small) var(--pine-green-color);
    }

    @include no-aside {
      height: var(--space-large);
    }
  }

  .logo {
    background-color: var(--primary-color);
    flex-basis: var(--renblad-aside-width);
    display: flex;
    align-items: flex-end;
    color: var(--white-color);
    flex-shrink: 0;
    padding: 8px;
    text-transform: uppercase;
    font-size: 1.5rem;
    letter-spacing: 1.2px;
    justify-content: center;

    @include no-aside {
      flex-basis: 50%;
      align-items: center;
    }

    &-main {
      font-weight: 900;
    }

    &-ext {
      font-weight: 500;
      @include no-aside {
        display: none;
      }
    }
  }

  .planbok-menu {
    flex-basis: 70%;
    display: flex;
    align-items: flex-end;
    padding: 0px var(--space-medium);
    gap: var(--space-medium);

    @include no-aside {
      display: none;
    }

    a {
      padding: var(--space-x-small) 0px;
      color: var(--font-color);

      &:hover,
      &.active {
        border-bottom: 4px solid var(--primary-color);
        padding-bottom: calc(var(--space-x-small) - 4px);
      }
    }
    .renblad-menu__edit-status {
      margin-left: auto;
    }
  }
  .planbok-mobile-menu {
    display: none;
    flex-basis: 50%;

    flex-direction: row-reverse;

    @include no-aside {
      display: flex;
    }

    button {
      padding: 8px 16px;
      display: flex;
      align-items: center;
      gap: 8px;
      svg {
        transform: rotate(180deg);
        width: 20px;
      }
    }

    .planbok-level-nav {
      background-color: var(--white-color);
      position: absolute;
      height: 1000px;
      left: 0;
      right: 0;
      top: calc(var(--space-large));
      bottom: 0;
      z-index: 10;

      display: flex;
      flex-direction: column;
      gap: var(--space-xx-small);

      padding: var(--space-x-small);

      a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--font-color);
        background-color: var(--tertiary-shade);
        padding: var(--space-xx-small) var(--space-x-small);

        svg {
          width: 25px;
          height: 25px;
        }

        &.active {
          background-color: var(--primary-color);
          color: var(--white-color);
          margin-top: var(--space-x-small);
        }
      }
      .sub-pages {
        display: flex;
        flex-direction: column;
        gap: var(--space-xx-small);
        a.sub-page {
          background-color: var(--tertiary-color);
        }
        margin-bottom: var(--space-x-small);
      }
    }
    .planbok-table-of-content {
      margin-top: var(--space-x-small);
      a {
        background: inherit;
        color: var(--primary-color);
        text-decoration: underline;
        text-underline-offset: 4px;
      }
    }
  }
}

.planbok-sidemenu {
  flex-basis: var(--renblad-aside-width);
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: var(--space-small);
  padding: var(--space-x-large) var(--space-medium);

  @include no-aside {
    display: none;
  }

  a {
    display: block;
    color: var(--font-color);
    background-color: var(--tertiary-color);
    padding: var(--space-xx-small) var(--space-x-small);
    font-weight: 500;

    &:hover,
    &.active {
      background-color: var(--dark-pine-color);
      color: var(--tertiary-color);
    }
  }
}

.planbok-admin {
  &-documents {
    padding: var(--space-large);

    max-width: var(--renblad-max-width);
    margin: 0 auto;

    .document-list-item {
      display: flex;
      gap: var(--space-xx-small);
      align-items: center;
      a {
        color: var(--primary-color);
      }
    }
  }
  &-cards {
    padding: var(--space-large);
    background-color: var(--tertiary-color);
    max-width: var(--renblad-max-width);
    margin: 0 auto;

    margin-bottom: var(--space-large);

    .top-card {
      padding: var(--space-small);
      border: 1px solid var(--dark-pine-color);
      background-color: var(--white-color);
      position: relative;
      width: fit-content;

      &-wrapper {
        display: flex;
        gap: var(--space-small);
        align-items: flex-end;
      }

      button {
        display: flex;
        align-items: center;
        gap: var(--space-xxx-small);
      }

      &:after {
        content: "";
        position: absolute;
        top: -1px;
        left: var(--space-medium);
        width: 90px;
        height: 12px;
        background-color: var(--secondary-color);
      }

      &:before {
        content: "";
        position: absolute;
        /* width: 100%; */
        height: 3px;
        bottom: calc(var(--space-medium) * -0.5);
        right: -3000px;
        left: 0;
        border: 1px solid var(--dark-pine-color);
        border-style: solid none;
      }
    }

    .sub-card {
      padding: var(--space-small);
      border: 1px solid var(--dark-pine-color);
      background-color: var(--tertiary-color);
      width: fit-content;
      height: max-content;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        width: 1px;
        height: calc(var(--space-medium) / 2);
        top: calc(var(--space-medium) * -0.5);
        border: 1px solid var(--dark-pine-color);
      }

      button {
        display: flex;
        align-items: center;
        gap: var(--space-xxx-small);
      }

      .expander {
        position: absolute;
        bottom: var(--space-xx-small);
        right: var(--space-xx-small);
      }

      .document-list-wrapper {
        display: none;
      }
      .document-list {
        li {
          button {
            margin: 0px;
            padding-left: 0px;
          }
        }
      }

      &.expanded {
        .document-list-wrapper {
          display: block;
        }
        button svg {
          transform: rotate(180deg);
        }
      }
    }

    .top-section {
      display: flex;
      flex-direction: column;
      gap: var(--space-medium);
      margin: var(--space-large) 0;
      overflow: hidden;
    }

    .sub-section {
      display: flex;
      flex-wrap: wrap;
      gap: var(--space-small);
    }

    .create-card-button {
      height: fit-content;
      margin: 0px;
    }
  }
  .create-card-form,
  .edit-card-form,
  .create-document-form {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    padding: var(--space-x-small);
    background: var(--white-color);
    gap: var(--space-xx-small);

    button {
      margin: 0px;
      display: block;
    }

    .form-input {
      display: flex;
      flex-direction: column;

      label {
        font-weight: 400;
        font-size: var(--font-size);
      }
      input,
      textarea,
      select {
        padding: var(--space-xx-small);
        font-size: var(--font-size);
        font-weight: 300;
      }

      textarea {
        min-height: 200px;
        min-width: 100%;
      }
    }

    .buttons {
      display: flex;
      flex-direction: column;
      gap: var(--space-xxx-small);
    }
  }
}
