.c-form-block input[type="text"],
input[type="email"] {
  width: 100%;
  padding: $spacingSmall $spacing;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: inherit;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-weight: 300;
}

.checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  padding-top: 0.05em;
  padding-bottom: 0.1em;
  margin-bottom: 12px;
  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 10;
    width: 10;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    //background-color: #eee;
    background-color: $colorWhite;
    border: 1px solid #ccc;
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark,
  input:focus ~ .checkmark {
    background-color: #ccc;
  }

  input:focus ~ .checkmark {
    border: solid 1px #000000;
  }

  input:checked ~ .checkmark {
    background-color: $colorPineGreen;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &.checkbox-small {
    padding-left: 25px;
    margin-bottom: 0px;

    .checkmark {
      width: 16px;
      height: 16px;

      &:after {
        left: 4px;
        top: 0px;
      }
    }
  }
}
/* add focus around label */
.checkbox:focus-within {
  outline: 1px dotted Highlight;
  outline: 5px auto -webkit-focus-ring-color;
}

.form-alert {
  padding: 0.75rem 1.25rem;

  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.form-popout {
  position: fixed;
  top: 45%;
  left: 45%;
  transform: scale(2, 2);
  z-index: z-index(content);

  &.form-alert-inactive {
    display: none;
  }
}

.form-alert-fail {
  background-color: $colorPlum;
}

.form-alert-success {
  color: #155724;
  background-color: $colorPistacchio;
  border-color: $colorPineGreen;
}
