.c-block-content {
  position: relative;

  // &:hover {
  //   .block-utils__menu {
  //     display: flex;
  //   }
  // }
}

.block-utils {
  display: flex;
  float: right;
  position: relative;  

  transition: background-color 0.2s ease-out;

  &:hover {
    .block-utils__menu {
      display: flex;
    }
  }

  &__menu {
    display: none; 
    position: absolute;
    left: 100%;  
    top: 0;
    margin-left: var(--space-small);
    width: 25px;
    height: 100%;

    background-color: white;
    outline: 1px solid $color3000Secondary;
    box-sizing: border-box;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 5px;
    padding: 8px;

    &-content {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: var(--space-small);

      a {
        display: flex;
        align-items: center;
      }
    }
  }
}
