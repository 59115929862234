/* Overrides for content within MUI-elements */

.MuiAlert-message .c-portableText p {
  display: inline;
  line-height: inherit;
  font-size: inherit;
  font-weight: inherit;

  a,
  ren-link,
  c-portableText__link {
    &:link,
    &:visited,
    &hover {
      color: inherit;
    }
  }
}
