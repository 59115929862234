.c-renblad-card {
  transition: transform 0.15s ease-in-out;
  background-color: var(--white-color);
  padding: var(--space-small);
  min-height: 14em;
  display: flex;
  flex-grow: 1;
  align-items: stretch;
  border: 1px solid var(--black-color);
}

.c-renblad-card--spacing {
  display: flex;
  flex-direction: column;
}

.c-renblad-card__header {
  display: flex;
  font-size: var(--label-font-size);
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin: 0 0 var(--space-x-small);
  font-weight: var(--font-weight);
}

.c-renblad-card__content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.c-renblad-card__lede {
  font-size: var(--font-size);
  line-height: var(--line-height);
  margin-bottom: 0;
}

.c-renblad-card__arrow {
  margin-top: var(--space-small);
  color: var(--pine-green-color);
}

@media (max-width: 60em) {
  .c-renblad-card__content {
    h3 {
      font-size: var(--heading-largest-font-size);
    }
  }
  .c-renblad-card__lede {
    font-size: var(--lead-font-size);
  }
}

@media all and (-ms-high-contrast: none) {
  .c-renblad-card--spacing {
    width: 100%;
  }

  .c-renblad-card__content {
    width: 100%;
  }
}
