.rich-text-debugger {
  padding: $spacing;

  &__buttons {
    display: flex;
    gap: $spacingSmall;
    button {
      margin: 0;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    div {
      overflow: scroll;
    }
  }
}
