ul {
  margin-top: $spacing;
  margin-bottom: $spacing;
  list-style: none;
  padding-inline-start: 1em;
}

li {
  font-size: 1em;
  margin-top: $spacingSmall;
  margin-bottom: $spacingSmall;
}

.list-style {
  font-weight: 300;

  &_disc {
    list-style: none;
    & > li {
      word-break: keep-all;
      &:last-child {
        margin-bottom: $spacing;
      }
    }
  }

  &_lower-latin {
    list-style: lower-latin;

    & > li {
      &:last-child {
        margin-bottom: $spacing;
      }
    }
  }

  &_numeric {
    list-style: numeric;

    & > li {
      &:last-child {
        margin-bottom: $spacing;
      }
    }
  }
}
