.c-toc-accordion {
  /* this value is dependtant on the height of nav/other stuff that will exist above it */
  top: 1.8rem;

  position: sticky;
  padding: $spacing;
  background: $colorWhite;
  border-bottom: 2px solid $colorGreySky;

  z-index: z-index(navigation);
  filter: drop-shadow(0px 6px 4px rgba(0, 0, 0, 0.05));
}
