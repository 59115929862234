/** Font colors **/

.u-text--primaryColor {
  color: var(--primary-color);
}
.u-text--primaryColorHover {
  color: var(--primary-color);

  &:hover {
    font-weight: 500;
    text-decoration: underline;
  }
}

.u-text--secondaryColor {
  color: var(--tertiary-color);
}
.u-text--secondaryColorHover {
  color: var(--tertiary-color);

  &:hover {
    color: var(--tertiary-shade);
  }
}

.u-text--lightGreen {
  color: $colorBrightGreen;
}
.u-text--white {
  color: $colorWhite;
}
.u-text--darkGreen {
  color: $colorPineGreen;
}
.u-text--black {
  color: $colorBlack;
}

.u-text--grey {
  color: var(--dark-clouds-color);
}

.u-bg--darkGreen {
  background-color: $colorPineGreen;
}

.u-roboto500 {
  font-weight: 500;
}

.u-roboto300 {
  font-weight: 300;
}

.u-rm-td {
  text-decoration: none;
}

.u-text--bold {
  font-weight: bold;
}

.u-text--center {
  text-align: center;
}
.u-text--right {
  text-align: right;
}

.u-fontSmall {
  font-size: $fontSmall;
}

.u-fontBase {
  font-size: $font;
}

.u-fontLarge {
  font-size: $fontLarge;
}

.u-fontLead {
  font-size: $fontLarge;
  line-height: 1.5;
  font-weight: 400;
}

.u-highlight {
  color: $colorDarkClouds;
  font-style: italic;
  font-size: 0.9em;
  color: #707070;

  em {
    color: black;
    font-style: normal;
    font-weight: 500;
    background-color: $colorSunny;
    //padding: 0.1em $spacingTiny;
  }
}

.u-header-title {
  line-height: 1.4;
  font-weight: 500;
}
