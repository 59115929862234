.c-open-form-button {
  //background-color: $colorBrightGreen;
  background-color: var(--plum-color);
  padding: var(--space-small) var(--space-large);
  margin: auto;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  color: var(--white-color);
}

button:hover {
  text-decoration: underline;
}

@media (max-width: 40em) {
  .c-open-form-button {
    width: 100%;
  }

  .c-open-form-button--expanded {
    background-color: var(--plum-color);
  }
}

.c-open-form-button--expanded {
  width: 100%;
}
@media screen and (min-width: 40em) {
  .c-open-form-button:hover {
    background-color: var(--plum-color);
  }
}

.c-open-form-icon {
  margin-left: $spacingSmall;
}
.c-open-form-icon--expanded {
  transform: rotate(180deg);
}

.c-header-form {
  background-color: $colorPineGreenOpacity;
  width: 100%;
  display: block;
  height: 300vh;
  z-index: z-index(content);
  position: absolute;
  left: 0;
  margin-top: 0;

  font-size: 1.2rem;
}
.c-form-block {
  opacity: 1;
  z-index: z-index(content-top);
}

.c-form--bold-text {
  font-family: "Roboto";
}

.c-form__submitfield {
  margin-top: $spacingLarge;
}

.c-form__message {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: $spacingSmall $spacing;
  width: 100%;
  height: $spacingHuge;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-weight: 300;
  font-size: $font;
  border-color: #ccc;
  border-radius: 4px;
}
