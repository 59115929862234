/* Listing properties */
.o-card-listing {
  display: grid;
  column-gap: $spacing;
  row-gap: $spacing;
  grid-template-columns: 1fr;
}

@media screen and (min-width: 40em) {
  .o-card-listing {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 60em) {
  .o-card-listing {
    grid-template-columns: repeat(3, 1fr);
  }
  .o-card-listing--half {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Listing fix for IE11 */
@media all and (-ms-high-contrast: none) {
  .o-card-listing {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    a {
      margin-bottom: $spacing;
    }
  }
  .o-card-listing__item {
    flex: 0 1 100%;
  }
  @media screen and (min-width: 40em) {
    .o-card-listing__item {
      flex: 0 1 49%;
    }
  }
  @media screen and (min-width: 60em) {
    .o-card-listing__item {
      flex: 0 1 32%;
    }
    .o-card-listing__item--half {
      flex: 0 1 49%;
    }
  }
}

/* Sideways scrolling listing used in header on mobile */
@media screen and (max-width: 40em) {
  .o-card-listing--scroll {
    display: grid;
    grid-gap: $spacing;
    grid-auto-flow: column;
    overflow-x: scroll;
    .c-card-minor {
      width: $spacingLarge * 9;
      .c-card-minor__top-bar--green {
        width: 100px;
      }
    }
  }
}
