.c-button-list {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
}
.c-button-list li {
  margin-top: 0;
  margin-bottom: 0;
}

.c-button-list__item {
  background-color: $colorPistacchio;
  padding: 0.8em $spacing;
  display: flex;
  justify-content: space-between;
  margin: 0;
  color: $colorBlack;
  font-weight: 500;
  transition: transform 0.15s ease-in-out;
}

.c-button-list__item:hover {
  transform: translate($spacing/4, $spacingNegative/4);
}
