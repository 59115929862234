.c-metadata-content {
  .c-editorpage-content {
    max-width: var(--renblad-content-width);

    &__purpose {
      .renblad-purpose-form {
        &__textarea {
          width: 100%;
          height: var(--space-xxx-large);
          font-size: var(--body-font-size);
          font-weight: var(--font-weight);
          padding: var(--space-small);
        }
      }
    }

    &__title {
      margin-top: var(--space-small);
      margin-bottom: var(--space-small);

      button.c-button-simple {
        display: flex;
        align-items: center;

        margin-top: 0;
        padding: 0;

        svg {
          margin-right: var(--space-xx-small);
        }
      }

      input {
        width: 100%;
        font-size: var(--title-font-size);
        font-weight: var(--font-weight);
        padding: var(--space-small);
      }
    }

    &__changelog {
      margin-top: var(--space-small);

      &--blockcontent {
        padding: var(--space-small);
        border: 1px solid var(--pine-green-color);
      }

      p#update-error {
        padding: $spacing;
        border-left: 5px solid red;
        background-color: pink;
      }

      .renblad-changelog-form {
        &__textarea {
          width: 100%;
          font-size: var(--body-font-size);
          font-weight: var(--font-weight);
          padding: var(--space-small);
        }
      }
    }

    &__access {
      margin-top: var(--space-large);
      h3 {
        margin-top: var(--space-small);
      }
    }

    &__publish {
      margin-top: var(--space-small);
      h3 {
        margin-top: var(--space-small);
      }
    }

    .checkbox {
      margin-top: var(--space-small);
    }

    &__version {
      p {
        margin-top: var(--space-xx-small);
      }
    }
  }
  .c-publish-error-box {
    margin-top: var(--space-large);
    ul li {
      list-style: disc;
    }
  }
}
