.c-link-preview {
  .c-link-preview-popover {
    width: $narrowWidth - 340px;
    height: $narrowWidth - 340px;
    padding: $spacingTiny $spacing;
    overflow-y: auto;
  }

  &.c-link-preview-outline:focus {
    outline: $colorDarkClouds solid 1px;
  }

  .c-link-preview-tag {
    margin: $spacing 0 $spacingTiny 0;
  }

  & div.c-renblad-content {
    min-width: 100%;
  }
}

.c-link-preview-icon {
  vertical-align: text-bottom;
}
