.c-simple-link {
  color: $colorBlack;
  padding: $spacingSmall 0;
  display: flex;
  align-items: center;
  transition: 0.15s;
  margin-bottom: $spacingTiny;
}
.c-simple-link:hover {
  border-radius: $spacingSmall;
  background-color: $colorWhite;
  padding: $spacingSmall $spacing;
  margin: 0 $spacingNegative $spacingTiny;
}

.c-simple-link__title {
  margin: 0;
  width: 68%;
  font-weight: 500;
}

.c-simple-link__category {
  width: 22%;
  margin: 0;
}

.c-simple-link__arrow {
  display: flex;
  justify-content: flex-end;
  width: 10%;
  color: $colorPineGreen;
}

@media (max-width: 60em) {
  .c-simple-link {
    flex-direction: column;
    align-items: flex-start;
    padding: $spacing $spacingTiny;
    border-bottom: 1px $colorDarkClouds solid;
  }

  .c-simple-link__title {
    width: 100%;
    font-weight: 500;
  }

  .c-simple-link__category {
    width: 100%;
  }

  .c-simple-link__arrow {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: $spacingNegative;
    margin-left: $spacingNegative;
  }
}
