h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  line-height: 1.2;
  font-weight: normal;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 22px;
}

h4,
h5,
h6 {
  font-size: 18px;
}

@media (max-width: 59.99em) {
  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 20px;
  }

  h4,
  h5,
  h6 {
    font-size: 16px;
  }
}
