.o-wrapper {
  max-width: $siteWidth;
  margin-left: auto;
  margin-right: auto;
  padding-left: $spacing;
  padding-right: $spacing;
  box-sizing: border-box;
}

.o-wrapper--wide {
  max-width: $wideWidth;
}

.o-wrapper--medium {
  max-width: $mediumWidth;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  padding-left: $spacing;
  padding-right: $spacing;
}
.o-wrapper--rp {
  padding-left: 0;
  padding-right: 0;
}

.o-wrapper--narrow {
  max-width: var(--max-content-width);
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}

.with-grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 3fr;
}
