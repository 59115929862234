.c-large-card {
  background-color: $colorPistacchio;
  padding: 1.8rem $spacing;
  display: flex;
  justify-content: space-between;
  margin: 0;
  color: $colorBlack;
  font-weight: 500;
  transition: transform 0.15s ease-in-out;
}
.c-large-card p {
  font-weight: 400;
}

.c-large-card:hover {
  transform: translate($spacing/4, $spacingNegative/4);
}

.c-large-card__title {
  font-weight: 500;
}
