// This is copyable-link on headings, not on blocks, tables, etc
h2,
h3,
h4,
h5,
h6 {
  a.copyable-link {
    //hide copyable-link in headings
    opacity: 0;

    padding-left: 5px;
  }
}

h2,
h3,
h4,
h5,
h6 h2 {
  &:hover a.copyable-link {
    //show copyable-link in headings on hover
    opacity: 1;
  }
}
