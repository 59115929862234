.c-relevant-courses__header {
  margin-bottom: $spacing;
}

.c-relevant-courses__categories {
  display: flex;
  padding: var(--space-x-small) var(--space-small);
}

.c-relevant-courses__title {
  width: 40%;
}

.c-relevant-courses__date {
  width: 25%;
}

.c-relevant-courses__location {
  width: 20%;
}

.c-relevant-courses__status {
  width: 12%;
}

@media (max-width: 59.99em) {
  .c-relevant-courses__categories {
    display: none;
  }
}
